.header{
    /* height: 3rem; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #2C3F50;

}

.headerImageCon{
  width: 150px ;
}

.headerImage{
  width: 100%;
  height: 100%;
}

.editBtn{
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100%;
    width: 95%;
 
}

.btn{
    background: none; /* Removes background color */
    color: white; /* Sets text color to white */
    border: 1px solid white; /* Optional: Add border to make the button visible */
    padding: 10px 20px; /* Adjust padding as needed */
    font-size: 16px; /* Adjust font size as needed */
    cursor: pointer; /* Shows a pointer cursor on hover */
    border-radius: 4px;
}

.ContactContainer{

    margin-top: 20px;
    margin-bottom: 20px;
}


.modalContent {
    background: #ffffff;
    border-radius: 8px;
    padding: 10px;
    position: relative;
  }
  
  .contactContainertext {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .dropdown {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s, background-color 0.3s;
  }
  
  .dropdown:focus {
    border-color: #007bff;
    background-color: #ffffff;
    outline: none;
  }
  
  .wrapperButton {
    display: flex;
    justify-content: flex-end;
  }
  
  .closeButton {
    background-color: #FF8461;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .closeButton:hover {
    background-color: #FF8461;
  }

  .meImage{
    width: 100%;
    }
    
    .imgContainer{
    width: 170px;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    }
    
    .WrapperimgCon{
      display: flex;
      justify-content: center;
    }
    
    