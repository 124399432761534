/* LoginScreen.module.css */
*{
    margin: 0px;
}
 

.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: url('../../Assets/donut_desktop1.png') no-repeat center center;
  
  }
  
  .loginBox {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .marcusLogo {
    width: 200px; 
    margin-bottom: 20px;
  }
  
  .hubspotLogo {
    width: 150px; 
    margin: 20px 0;
  }
  
  .authButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF7A59;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
  }
  
  .authButton .microsoftLogo {
    width: 24px;
    margin-right: 10px;
    
  }

  .crmText{
    color: #45535E;
    text-align: center;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .hubspotContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:15px;

  }

  .welcomeMsg{
    color: #45535E;
    font-size: 1.125rem;
    font-weight: 400;
  }
  

  @media (max-width: 768px) {
    .loginBox {
        padding: 19px;

    }
}