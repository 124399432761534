.formWarpper{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #454545;
}

.loadingtext{
color: white;
font-size: 24px;
}

