.formContainer {
  max-width: 500px;
  margin: auto;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.formHeader {
  background: linear-gradient(90deg, #45A9C4 0%, #51C5AE 100%);
  color: white;
  text-align: center;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
}

.formBody {
  padding: 20px;
}

.formGroup {
  margin-bottom: 15px;
}

.formGroup label {
  color: #33475B;
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.inputField,
.selectField {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.inputField:focus,
.selectField:focus {
  border-color: #00aaff;
  outline: none;
}

.checkboxLabel {
  display: flex;
  align-items: center;

}

.checkboxInput {
  margin-right: 8px;
}

.errorText {
  color: red;
  font-size: 12px;
}

.formFooter {
  display: flex;
  justify-content: center;
  padding: 20px 10px;
  background-color: #F6F8FA;
  gap: 10px
}

.submitButton,
.buttonEnabled,
.cancelButton {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  box-sizing: border-box;

}

.submitButton {

  color: #B0C1D4;
  background-color: #EAF0F6;
  font-weight: 600;
}

.addButton {
  background-color: #ffaa00;
  color: white;
}

.cancelButton {
  background-color: transparent;
  color: #FF8461;
  border-radius: 0.25rem;
  border: 2px solid #FF8461;
}


.requiredAsterisk {
  color: #33475B;
  margin-left: 4px;
}

.textCon {
  display: flex;
  align-items: center;
  gap: 2px
}

.checkboxContainer {
  display: flex;
  align-items: center;
}


.selectField {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  appearance: none;
  /* Hides the default dropdown arrow */
  -webkit-appearance: none;
  /* Safari compatibility */
  -moz-appearance: none;
  background: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 width=%2224%22 height=%2224%22 viewBox=%220 0 24 24%22 fill=%22none%22%3E%3Cpath d=%22M7 10L12 15L17 10H7Z%22 fill=%22%2305A3BD%22/%3E%3C/svg%3E') no-repeat right 0.5rem center;
  background-size: 1.2rem auto;
  padding-right: 2rem;
  /* Add padding to make space for the custom arrow */
}

.customSelect {
  position: relative;
}

.contactContainer {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: #33475B;
}

.contactContainertext{
  text-align: center;
  color: #33475B;
  margin-top: 1rem;
}


/* .dedicatedContainer {
  width: 50%;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.ddimage{
  width: 100%;
} */


.ButtonForm {
  /* display: flex;
  justify-content: center; */
  /* gap: 10px; */
  margin-top: 1rem;
}

.pButton {
  border-radius: 0.25rem;
  background: #F8761F;
  color: white;
  border: none;
  outline: none;
  padding: 10px;
  cursor: pointer;
}

.skipButton {
  border-radius: 0.25rem;
  color: #0091AE;
  border: none;
  outline: none;
  padding: 10px;
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
}

.hrLine{
  margin-top: 22px;
border-top: 1px solid #CBD6E2;
}

.marginBottom{
  margin-bottom: 5px;
}

.svgContainer{
  margin-top: 7px;
}

/* for listing data present */
.searchDropDown {
  position: relative; /* Ensure the suggestions are positioned relative to this container */
}

.inputField {
  width: 100%;
  padding: 8px;
}

.suggestionsList {
 /* Position the suggestions list below the input */
  top: 100%; /* Place it below the input field */
  left: 0;
  width: 100%;
  max-height: 150px; /* Adjust height as needed */
  overflow-y: auto; /* Scroll if there are many suggestions */
 /* Border to separate suggestions */
  border-radius: 4px; 
  z-index: 10; 
}

.suggestionItem {
  padding: 8px;
  cursor: pointer;

}

.suggestionItem:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}


.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.inputField {
  width: 100%;
  padding-right: 2rem; 
}

.clearButton {
  position: absolute;
  right: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #aaa;
}

.clearButton:hover {
  color: #333;
}


.buttonEnabled {
  background-color: #FF8461; 
  color: white;
  font-weight: 600;
}


.contactDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 20px;
}

.radioContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  box-sizing: border-box; 

}

.emailMatch {
  width: 100%;
}

.noEmailMatch {
  width: 100%;
}

/* .contactDetails > .radioContainer:only-child {
  flex: 0 0 70%; 
  max-width: 70%; 
} */


.radioContainer:hover {
  background-color: #f9f9f9;
}


.contactInfo {
  display: flex;
  flex-direction: column;
}

.contactInfo p {
  margin: 5px 0;
  font-size: 16px;
  color: #333;
}


.borderHighlight {
  border: 2px solid #FF8461;
  background-color: #f9f9f9;
}

.fontBold{
  display: inline-block;
  font-weight: 450;
  width: 95px;
}

.marginTop{
  margin-top: 1.5rem;
  color: #33475B;
  font-size: 15px;
}

.color{
  color: #33475B;
}

.disabledButton {
  background-color: gray;
  cursor: not-allowed;
  color: white; /* Optional: Change text color */
}

.textAlignc{
  text-align: center;
}


.scrollable {
  max-height: 300px; /* Set the maximum height according to your design */
  overflow-y: auto;
}

/* Optional: Customize the scrollbar appearance */
.scrollable::-webkit-scrollbar {
  width: 6px;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.scrollable::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

.spacing{
  margin: 7px 0px
}


.meImage{
width: 100%;
}

.imgContainer{
width: 170px;
display: flex;
justify-content: center;
margin-bottom: 1rem;
}

.WrapperimgCon{
  display: flex;
  justify-content: center;
}


